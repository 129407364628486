import React from 'react'

const CoInvestor = ({ coInvestorsList }) => {
  return (
    <div className="d-flex justify-content-center w-100 mt-4 flex-wrap">
      {
        coInvestorsList.map((coInv, index) => (
          <div key={index} className="d-flex co-investor-wrapper flex-column text-center align-items-center px-2 mb-3">
            <img
              src={
                coInv.imgUrl ?
                  process.env.REACT_APP_BASE_URL + "/" + coInv.imgUrl :
                  process.env.PUBLIC_URL + "/img/placeholder.png"
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = process.env.PUBLIC_URL + "/img/placeholder.png";
              }} className="co-inv-img" alt=""
            />
             <p className="mb-2 mt-0 fs-6 text-dark fw-semibold">{coInv.fullName}</p>
             <p className="mb-0 mt-10 fs-7 text-center">{coInv.description}</p>
          </div>
        ))
      }
    </div>
  )
}

export default CoInvestor
