import React, { useEffect } from 'react';
import { apiTokenAuth, getInvestorOpportunity } from '../apis/apis';
import ReportIcon from '@mui/icons-material/Report';
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { DRAW_DOWN_TOKEN_LOGIN, FATCA_TOKEN, MCA_TOKEN_LOGIN, NOMINATION_TOKEN } from '../../utils/constants/constants';
import { useLocation } from 'react-router-dom';
import { Button, Dialog } from '@mui/material';
import { LS_INV_CODE, getUserAuth, setDashboardTab, setPartner_Id, setUserAuth } from '../../utils/LocalStorageUtils';
import DrawDownComponent from '../components/DrawDownComponent';
import { useSnackbar } from '../../context/SnackbarContext';
import { fetchPartnerIdFromApi } from '../../utils/FetchLogo';

function AgreementLP() {

  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');
  const type = searchParams.get('type');
  const [openAlert, setOpenAlert] = useState(false);
  const [showDrawdown, setShowDrawdown] = useState(false)
  const [oppDetail, setOppDetail] = useState(null)
  const [drawdownData, setDrawdownData] = useState(null)
  const [partnerId, setPartnerId] = useState(0);
  const { showSnackbar } = useSnackbar()
  const [logo, setLogo] = useState(null);

  const getTokenAuth = () => {

    var authToken = {};
    setIsLoading(true);
    // localStorage.clear();
    apiTokenAuth({ "token": token, "type": type })
      .then((data) => {
        setIsLoading(false);
        authToken = { responseStatus: true, reponseMessage: "success", "authStatus": data.status };
        if (data.status) {
          const { jwt, user } = data.data;
          localStorage.setItem("JWT", jwt);
          if (type == MCA_TOKEN_LOGIN) {
            if (user.invPref === 0) {
              history.replace("/user/investmet-method");
            } else {
              history.replace("/user/agreement");
            }
          } else if (type == DRAW_DOWN_TOKEN_LOGIN) {
            setIsLoading(true)
            getInvestorOpportunity({ token: token })
              .then((res) => {
                setIsLoading(false)
                if (res.status) {
                  setOppDetail(res.data);
                  setDrawdownData(res.data);
                  setShowDrawdown(true);
                  // if (getUserAuth()) {
                  //   setDashboardTab(0);
                  //   history.push('/user/home');
                  // } else {
                  //   history.push('/')
                  // }
                  // history.push(`/user/invest-now?oppRid=${res.data.oppRid}&token=${token}&showDrawDown=${true}`,{...res.data})
                } else {
                  setErrorMessage(res.description)
                }
              })
              .catch((e) => {
                setErrorMessage(e);
                authToken = { responseStatus: false, reponseMessage: e, "authStatus": null };
              })
          } else if (type == NOMINATION_TOKEN) {
            history.replace("/user/nomination");
          } else if (type == FATCA_TOKEN) {
            history.replace("/user/fatca");
          }
        } else {
          setErrorMessage(data.description);
        }
      }).catch((error) => {
        setIsLoading(false);
        setErrorMessage(error);
        authToken = { responseStatus: false, reponseMessage: error, "authStatus": null };
      })
      .finally(() => {
        var customAuthEvent = new CustomEvent('authCustomEvent', { detail: authToken });
        window.dispatchEvent(customAuthEvent);
      });
  }

  const fetchLogo = (partnerId) => {
    return `${process.env.REACT_APP_BASE_URL}/logo/${partnerId}`;
  };

  useEffect(() => {
    fetchPartnerIdFromApi(
      setPartnerId,
      setPartner_Id,
      showSnackbar
    );
    const logoUrl = fetchLogo(partnerId);
    setLogo(logoUrl);
  }, []);

  useEffect(() => {
    getTokenAuth();
    function handleCustomEvent(event) {
      console.log('Custom event received:', event.detail);
    }

    window.addEventListener('authCustomEvent', handleCustomEvent);

    return () => {
      window.removeEventListener('authCustomEvent', handleCustomEvent);
    };
  }, []);

  if (showDrawdown) {
    const invCode = localStorage.getItem(LS_INV_CODE)

    return (
      <DrawDownComponent isEmailInvestment={false} path={drawdownData.drawdownFile.path} oppDetail={{ invCode: invCode, ...oppDetail }} token={token} ioRid={oppDetail.ioRid} />
    )
  }

  return (
    <div className="art-board py-md-4">
      <Dialog maxWidth="md" open={openAlert}>
        <div className="d-flex gap-5 align-items-center px-5 py-4">
          <h1 className="fs-x-large-medium text-purple p-0 m-0">Please provide the location permission to proceed</h1>
          <Button variant="contained" onClick={() => window.location.reload()}>Retry</Button>
        </div>
      </Dialog>

      <div className="container card-flat p-4 p-md-5">
        <img src={logo} alt={"logo"} width="180" />

        <div className="loading-page">
          {
            isLoading ? <div className="loading-spinner"></div> : <ReportIcon sx={{ fontSize: 80 }} color="action" />
          }
          <p className="loading-text">
            {
              isLoading ? 'Loading...' : errorMessage
            }
          </p>
        </div>
      </div>
    </div>

  );
}

export default AgreementLP;