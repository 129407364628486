import React, { useEffect, useRef, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { Autocomplete, CircularProgress, Divider, IconButton, Popper, Snackbar, TextField } from "@mui/material";
import { apiDematInfo, apiSaveBankInfo, apiSaveBasicDetail, getApiCityByRid, getCityNames, getCountryNames, getDataDictionaryValues, getUserDetails, uploadFile } from "../apis/apis";
import { DD_TYPE_INVESTOR_TYPE, DD_TYPE_BANK_TYPE, DD_TYPE_DP_NAMES } from "../../utils/constants/constants";
import "react-phone-input-2/lib/material.css";
import Grid from '@mui/material/Grid';
import { useSnackbar } from "../../context/SnackbarContext";
import { Add, AttachFile, Close } from "@mui/icons-material";
import CustomSnackbar from "../components/Toast/CustomSnackbar";
import { dark } from "@mui/material/styles/createPalette";

const InvestorDetails = () => {
  const { showSnackbar } = useSnackbar();
  const [bankNameList, setBankNameList] = useState([]);
  const [investorTypeList, setInvestorTypeList] = useState([]);
  const [dpNameList, setDpNameList] = useState([]);
  const [investor, setInvestor] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [showOtherInput, setShowOtherInput] = useState(false);
  const [showOtherDematName, setShowOtherDematName] = useState(false);
  const [showAccountNo, setShowAccountNo] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorData, setErrorData] = useState("");
  const [toastStatus, setToastStatus] = useState("");
  const [countryList, setCountryList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [countryRid, setCountryRid] = useState(0);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMoreCity, setHasMoreCity] = useState("");
  const [inputValue, setInputValue] = useState("");
  const listboxRef = React.useRef();
  const [isBottom, setIsBottom] = useState(false);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  const getUserDetailsList = async () => {
    setIsLoading(true);
    try {
      const res = await getUserDetails();
      setInvestor({
        ...res.data,
        readOnlyDobDoi: !!res.data?.dobDoi,
        readOnlyInvestorType: res.data?.investorType,
      });
    } catch (error) {
      showSnackbar(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getCountries = async () => {
    const countryCode = localStorage.getItem("countryCode");
    try {
      const data = await getCountryNames(countryCode);
      setCountryList(data);
      if (data && data.length > 0) {
        const countryRid = data[0]?.countryRid;
        if (countryRid) {
          setCountryRid(countryRid);
          setInvestor((prevInvestor) => ({
            ...prevInvestor,
            countryRid: countryRid,
          }));
        }
      }
    } catch (error) {
      showSnackbar("Error fetching countries:", error);
    }
  };

  const getCities = async (countryRid, page = 1, searchQuery = "", loadMore = false) => {
    setLoading(true);
    getCityNames(countryRid, page, 20, searchQuery)
      .then((newCities) => {
        if (newCities && newCities.length > 0) {
          setHasMoreCity("")
          if (loadMore) {
            setCityList((prevList) => {
              const uniqueCities = Array.from(
                new Set([...prevList, ...newCities].map((city) => city.cityRid))
              ).map((cityRid) => [...prevList, ...newCities].find((city) => city.cityRid === cityRid));
              return uniqueCities;
            });
            // Assuming each list item has a fixed height (for example, 30px).
            const itemHeight = 30; // Adjust this value to match your list item height.
            setTimeout(() => {
              const newScrollHeight = listboxRef.current.scrollHeight;
              const previousScrollHeight = listboxRef.current.scrollHeight - newScrollHeight;

              const itemCount = cityList.length;
              const scrollOffset = itemCount * itemHeight;

              listboxRef.current.scrollTop = scrollOffset;
            }, 0.5);
          } else {
            setCityList(newCities)
          }
        } else {
          setHasMoreCity("true")
        }
      })
      .catch((e) => {
        console.error('Error fetching cities:', e);
      })
      .finally(() => {
        setLoading(false);
        setIsBottom(false)
      });
  };
  useEffect(() => {
    // After loading new cities, adjust the scroll position 0.5%  up from the bottom to avoid scroll reset to top
    if (listboxRef?.current) {
      if (isBottom) {
        const newItemsHeight = listboxRef?.current?.scrollHeight;
        const offset = newItemsHeight * 0.05;
        listboxRef.current.scrollTop = newItemsHeight - offset;
      }
    }
  }, [isBottom]);

  const loadMoreCities = (e) => {
    const listBox = listboxRef.current;
    const isAtBottom = listBox.scrollHeight - listBox.scrollTop <= listBox.clientHeight + 10;
    if (isAtBottom && !loading) {
      setIsBottom(true);
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      getCities(investor?.countryRid, currentPage + 1, searchQuery, true);
    }
  };

  const getCityByRid = (cityRid) => {
    getApiCityByRid(cityRid)
      .then((data) => {
        if (data) {
          setInvestor((prevInvestor) => ({
            ...prevInvestor,
            cityRid: data?.cityRid,
            cityName: data?.cityName,
          }));
          setInputValue(data?.name);

          // Ensure the city from getCityByRid is in cityList
          setCityList((prevList) => {
            const isCityPresent = prevList.some((city) => city.cityRid === data.cityRid);
            if (!isCityPresent) {
              return [data, ...prevList];
            }
            return prevList;
          });
        }
      })
      .catch((error) => {
        console.error('Error fetching city by RID:', error);
      });
  };

  useEffect(() => {
    if (investor?.cityRid) {
      getCityByRid(investor.cityRid); // Fetch the exact city details
    } else {
      getCities(investor?.countryRid, 1, "", false)
    }
  }, [investor?.cityRid]);

  useEffect(() => {
    if (investor?.countryRid) {
      if (inputValue.length < 3) {
        getCities(investor?.countryRid, 1, "", false)
      }
    }
  }, [inputValue])

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'bankIndex') {
      if (value === 'other') {
        setInvestor((prevInvestor) => ({
          ...prevInvestor,
          bankIndex: 0,
          bankName: '',
          otherBankName: '',
        }));
        setShowOtherInput(true);
      } else if (value === '---') {
        setInvestor((prevInvestor) => ({
          ...prevInvestor,
          bankIndex: 0,
          bankName: '',
          otherBankName: null,
        }));
        setShowOtherInput(false);
      } else {
        setInvestor((prevInvestor) => ({
          ...prevInvestor,
          bankIndex: value,
          otherBankName: null,
        }));
        setShowOtherInput(false);
      }
    }

    if (name === 'dpName') {
      if (value === 'other') {
        setInvestor((prevInvestor) => ({
          ...prevInvestor,
          dpDdIndex: 0,
          dpName: '',
          otherDematName: '',
        }));
        setShowOtherDematName(true);
      } else if (value === '') {
        setInvestor((prevInvestor) => ({
          ...prevInvestor,
          dpDdIndex: 0,
          dpName: null,
          otherDematName: null,
        }));
        setShowOtherDematName(false);
      } else {
        setInvestor((prevInvestor) => ({
          ...prevInvestor,
          dpDdIndex: value,
          otherDematName: null,
        }));
        setShowOtherDematName(false);
      }
    }

    if (name === 'dpId' || name === 'dpClientId') {
      const newDpId = name === 'dpId' ? value : investor?.dpId;
      const newDpClientId = name === 'dpClientId' ? value : investor?.dpClientId;

      setInvestor((prevInvestor) => ({
        ...prevInvestor,
        [name]: value,
      }));

      if (newDpId?.length === 8 && newDpClientId?.length === 8) {
        setShowAccountNo(true);
        setInvestor((prevInvestor) => ({
          ...prevInvestor,
          dematAccNo: newDpId + newDpClientId,
        }));
      }
    }

    if (!['bankIndex', 'dpName', 'dpId', 'dpClientId'].includes(name)) {
      setInvestor((prevInvestor) => ({
        ...prevInvestor,
        [name]: value,
      }));
    }
  };

  const handleOtherBankNameChange = (e) => {
    setShowOtherInput(true);
    const { value } = e.target;
    setInvestor((prevInvestor) => ({
      ...prevInvestor, bankIndex: 0,
      otherBankName: value,
    }));
  };

  const handleOtherDematNameChange = (e) => {
    const { value } = e.target;
    setInvestor((prevInvestor) => ({
      ...prevInvestor, dpDdIndex: 0,
      otherDematName: value,
    }));
  };

  const getDataDictionary = () => {
    getDataDictionaryValues()
      .then((data) => {
        const invTypes = data.filter(
          (item) => item.ddType === DD_TYPE_INVESTOR_TYPE
        );
        const bankTypes = data.filter((item) =>
          item.ddType === DD_TYPE_BANK_TYPE
        )
        const dpNames = data.filter((item) =>
          item.ddType === DD_TYPE_DP_NAMES
        )
        setInvestorTypeList(invTypes);
        setBankNameList(bankTypes);
        setDpNameList(dpNames);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const uploadDocument = (e) => {
    setIsFileLoading(true);
    uploadFile(e.target.files[0])
      .then((res) => {
        setIsFileLoading(false);
        setInvestor(prevState => ({
          ...prevState,
          [e.target.name]: res.data,
        }));
      })
      .catch((err) => {
        setIsFileLoading(false);
        showSnackbar(err);
      });
  }
  const deleteDocument = (key) => {
    setInvestor(prevState => ({
      ...prevState,
      [key]: null,
    }));
  }

  useEffect(() => {
    setCurrentPage(1)
    getUserDetailsList();
    getDataDictionary();
    getCountries();
  }, []);

  const saveBasicDetails = () => {
    if (!investor?.cityRid) {
      showSnackbar("Please select a city")
      return;
    }
    setIsLoading(true);
    apiSaveBasicDetail({
      fullName: investor?.fullName,
      dobDoi: investor?.dobDoi?.split(' ')[0],
      investorType: investor?.investorType,
      address: investor?.address,
      countryRid: investor?.countryRid ? investor?.countryRid : countryRid,
      cityRid: investor?.cityRid,
      stateRid: investor?.stateRid
    })
      .then((res) => {
        showSnackbar('Data Saved');
      })
      .catch((err) => {
        showSnackbar(err);
      })
      .finally(() => setIsLoading(false));
  }

  const saveBankDetails = () => {

    if (investor?.bankIndex === 0 && (investor?.otherBankName === "" || investor?.otherBankName === null) && (investor?.bankName === "" || investor?.bankName === null)) {
      showSnackbar("Please select valid bank name");
      return;
    }
    setIsLoading(true);
    apiSaveBankInfo({
      // address: investor?.address,
      bankName: investor?.bankName,
      bankIndex: investor?.bankIndex,
      bankAddress: investor?.bankAddress,
      accountNo: investor?.accountNo,
      ifscSwift: investor?.ifscSwift,
      otherBankName: investor?.otherBankName,
      // investorType: investor?.investorType,
    })
      .then((res) => {
        showSnackbar('Data Saved');
      })
      .catch((err) => {
        showSnackbar(err);
      })
      .finally(() => setIsLoading(false));
  }
  
  const saveDematDetails = () => {
    setIsLoading(true);
    apiDematInfo({
      dematAccNo: investor?.dematAccNo,
      dpName: investor?.dpName,
      dpId: investor?.dpId,
      dpDdIndex: investor?.dpDdIndex,
      dpClientId: investor?.dpClientId,
      firstHolderName: investor?.firstHolderName,
      cmlCopyObject: investor?.cmlCopyObject,
      nsdlCdsl: investor?.nsdlCdsl,
      otherDematName: investor?.otherDematName,
    })
      .then((res) => {
        showSnackbar('Data Saved');
      })
      .catch((err) => {
        showSnackbar(err);
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12}>
          <p className="section-label fw-bold p-0 m-0">BASIC DETAILS</p>
        </Grid>

        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="Name"
              name="fullName"
              value={investor?.fullName || ""}
              id="fullName"
              onChange={handleInputChange}
              readOnly
            />
            <label htmlFor="fullName">Name</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="Inv Code"
              name="invCode"
              value={
                investor?.invCode !== null && investor?.invCode !== ""
                  ? investor?.invCode
                  : ""
              }
              id="invCode"
              onChange={handleInputChange}
              readOnly
            />
            <label htmlFor="invCode">Investor Code</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="Phone Number"
              name="number"
              value={
                (investor?.countryCode ? investor?.countryCode : "") +
                (investor?.mobile ? investor?.mobile : "") ?? ""
              }
              id="number"
              onChange={handleInputChange}
              readOnly
            />
            <label htmlFor="number">Phone Number</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="Email"
              name="email"
              value={
                investor?.email !== null && investor?.email !== ""
                  ? investor?.email
                  : ""
              }
              id="email"
              onChange={handleInputChange}
              readOnly
            />
            <label htmlFor="email">Email</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="DOB"
              name="dobDoi"
              type="date"
              value={
                investor?.dobDoi
                  ? new Date(investor.dobDoi).toISOString().split("T")[0] // Format to YYYY-MM-DD
                  : ""
              }
              id="dobDoi"
              onChange={handleInputChange}
              readOnly={investor?.readOnlyDobDoi}
            />
            <label htmlFor="dobDoi">DOB</label>
          </div>

        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating mb-3">
            <select
              class="form-select"
              id="investorType"
              name="investorType"
              onChange={handleInputChange}
              value={investor.investorType}
              aria-label="Default select example"
              disabled={investor?.readOnlyInvestorType}
            >
              <option value={null}></option>
              {investorTypeList?.map((item) => (
                <option value={item.ddIndex}>{item.ddValue}</option>
              ))}
            </select>
            <label htmlFor="investorType">Investor Type</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="Address"
              name="address"
              value={
                investor?.address !== null && investor?.address !== ""
                  ? investor?.address
                  : ""
              }
              id="address"
              onChange={handleInputChange}
            />
            <label htmlFor="address">Address</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          {investor.countryName ?
            <div className="form-floating">
              <input
                className="form-control"
                placeholder="Country"
                name="country"
                value={investor.countryName}
                id="address"
                onChange={handleInputChange}
                readOnly
              />
              <label htmlFor="address">Country</label>
            </div> :
            <div className="form-floating mb-4">
              {countryList?.length > 0 ? (
                <Autocomplete
                  options={countryList || []}
                  getOptionLabel={(option) => option.name || ""}
                  isOptionEqualToValue={(option, value) => option.countryRid === value?.countryRid}
                  value={countryList.find((country) => country.countryRid === investor.countryRid) || null}
                  onChange={(event, value) => {
                    setHasMoreCity("")
                    setCurrentPage(1)
                    setInvestor((prevInvestor) => ({
                      ...prevInvestor,
                      countryName: value ? value.name : "",
                      countryRid: value ? value.countryRid : 0,
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} label="Country" />}
                />
              ) : (
                <div className="form-control"></div>
              )}
              <label htmlFor="countryRid">Country</label>
            </div>
          }
        </Grid>

        <Grid item xs={6} md={3}>
          <Autocomplete
            options={
              investor?.cityRid && cityList.find((city) => city.cityRid === investor?.cityRid)
                ? cityList.filter((city) => city.cityRid === investor?.cityRid)
                : cityList
            }
            getOptionLabel={(option) => option.name || ""}
            isOptionEqualToValue={(option, value) => option.cityRid === value?.cityRid}
            value={cityList.find((city) => city.cityRid === investor?.cityRid) || null}
            inputValue={inputValue}
            onInputChange={(event, value, reason) => {
              if (reason === "input") {
                setInputValue(value);
                setCurrentPage(1);
                setSearchQuery(value);
                setInvestor({
                  ...investor,
                  cityRid: 0, // Reset cityRid when the user starts typing
                });
                setCityList([]);

                // Make sure countryRid is defined before calling getCities
                if (value?.length >= 3 && investor?.countryRid) {
                  setTimeout(() => {
                    getCities(investor.countryRid, 1, value, false); // Only pass valid countryRid
                  }, 500);
                }
              } else if (reason === "clear") {
                setInputValue("");
                setInvestor({
                  ...investor,
                  cityRid: 0, // Reset cityRid
                });
                setSearchQuery("");
              }
            }}
            onChange={(event, value) => {
              setInvestor((prevInvestor) => ({
                ...prevInvestor,
                cityRid: value ? value.cityRid : "",
                cityName: value ? value.name : "",
                stateRid: value ? value.stateRid : "",
              }));
            }}
            renderOption={(props, option) => (
              <li {...props} key={option.cityRid}>
                {option.name} {option.stateName && ` [${option.stateName}]`}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="City"
                placeholder="Search your city"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            disablePortal
            fullWidth
            PopperComponent={(props) => (
              <Popper
                {...props}
                style={{
                  ...props.style,
                  zIndex: 1.5,
                }}
              />
            )}
            ListboxComponent={(props) => (
              <ul
                {...props}
                ref={listboxRef}
                onScroll={hasMoreCity === "" ? loadMoreCities : undefined}
                style={{
                  overflowY: 'auto',
                  maxHeight: 280,
                  zIndex: 2,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <div className='d-flex'>
            <button disabled={isLoading} className="btn btn-primary" onClick={() => saveBasicDetails()}>Save</button>
          </div>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <p className="section-label fw-bold p-0 m-0">BANK DETAILS</p>
        </Grid>

        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <select
              class="form-select"
              id="bankIndex"
              name="bankIndex"
              onChange={handleInputChange}
              value={investor?.bankIndex === 0 && investor?.otherBankName ? 'other' : investor?.bankIndex}
              aria-label="Default select example"
            >
              <option ></option>
              {bankNameList?.map((item) => (
                <option value={item.ddIndex}>{item.ddValue}</option>
              ))}
              <option value={"other"}>Other</option>
            </select>

            <label htmlFor="bankIndex">Bank Name</label>

          </div>
          {(showOtherInput || (investor?.otherBankName && !investor?.bankIndex)) && (
            <div className="form-floating mb-3 mt-3">
              <input
                className="form-control"
                placeholder="Other Bank Name"
                onChange={handleOtherBankNameChange}
                name="otherBankName"
                value={investor?.otherBankName || ""}
                id="otherBankName"
                type="text"
              />
              <label htmlFor="otherBankName">Other Bank Name</label>
            </div>
          )}
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="Bank Address"
              name="bankAddress"
              value={investor?.bankAddress || ""}
              id="bankAddress"
              onChange={handleInputChange}
            />
            <label htmlFor="bankAddress">Bank Address</label>
          </div>

        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="Account No"
              name="accountNo"
              value={investor?.accountNo || ""}
              id="accountNo"
              onChange={handleInputChange}
            />
            <label htmlFor="accountNo">Account No</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="IFSC"
              name="ifscSwift"
              value={investor?.ifscSwift || ""}
              id="ifscSwift"
              onChange={handleInputChange}
            />
            <label htmlFor="ifscSwift">IFSC</label>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className='d-flex'>
            <button disabled={isLoading} className="btn btn-primary" onClick={() => saveBankDetails()}>Save</button>
          </div>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <p className="section-label fw-bold p-0 m-0">DEMAT DETAILS</p>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <select
              class="form-select"
              id="dpName"
              name="dpName"
              onChange={handleInputChange}
              value={investor?.dpDdIndex === 0 && investor?.otherDematName ? 'other' : investor?.dpDdIndex}
              aria-label="Default select example"
            >
              <option value={null}></option>
              {dpNameList?.map((item) => (
                <option value={item.ddIndex}>{item.ddValue}</option>
              ))}
              <option value={"other"}>Other</option>
            </select>
            <label htmlFor="investorType">Depository Participant Name</label>

          </div>

        </Grid>
        {(showOtherDematName || (investor?.otherDematName && !investor?.dpDdIndex)) && (
          <Grid item xs={6} md={3}>

            <div className="form-floating ">
              <input
                className="form-control"
                placeholder="Other DP Name"
                onChange={handleOtherDematNameChange}
                name="otherDematName"
                value={investor?.otherDematName || ""}
                id="otherDematName"
                type="text"
              />
              <label htmlFor="otehrDematName">Other DP Name</label>
            </div>
          </Grid>
        )}
        <Grid item xs={6} md={3}>
          {/* <div className="form-floating">
            <input
              className="form-control"
              placeholder="NSDL / CDSL"
              name="nsdlCdsl"
              value={investor?.nsdlCdsl || ""}
              id="nsdlCdsl"
              onChange={handleInputChange}
            />
            <label htmlFor="nsdlCdsl">NSDL / CDSL</label>
          </div> */}
          <div className="form-floating">
            <select
              className="form-select"
              id="nsdlCdsl"
              name="nsdlCdsl"
              onChange={handleInputChange}
              value={investor?.nsdlCdsl || ""}
            >
              <option value=""></option>
              <option value="NSDL">NSDL</option>
              <option value="CDSL">CDSL</option>
            </select>
            <label htmlFor="nsdlCdsl">NSDL / CDSL</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="DP ID"
              name="dpId"
              value={investor?.dpId || ""}
              id="dpId"
              onChange={handleInputChange}
              maxLength={8}
            />
            <label htmlFor="dpId">DP ID</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="Client ID"
              name="dpClientId"
              value={investor?.dpClientId || ""}
              id="dpClientId"
              onChange={handleInputChange}
              maxLength={8}
            />
            <label htmlFor="dpClientId">Client ID</label>
          </div>
        </Grid>
        {(showAccountNo || investor?.dematAccNo) && (
          <Grid item xs={6} md={3}>
            <div className="form-floating">
              <input
                className="form-control"
                placeholder="Demat Account Number"
                name="dematAccNo"
                value={investor?.dematAccNo || ""}
                id="dematAccNo"
                onChange={handleInputChange}
                maxLength={16}
                readOnly
              />
              <label htmlFor="dematAccNo">Demat Account Number</label>
            </div>
          </Grid>
        )}

        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="First Holder Name"
              name="firstHolderName"
              value={investor?.firstHolderName || ""}
              id="firstHolderName"
              onChange={handleInputChange}
            />
            <label htmlFor="firstHolderName">First Holder Name</label>
          </div>
        </Grid>

        <Grid item xs={6} md={6}>
          <div className="d-flex flex-column">
            <input
              className="d-none"
              id={`input-file`}
              type="file"
              name="cmlCopyObject"
              onChange={uploadDocument}
            />
            <span className="fs-normal-medium">CML COPY</span>

            <div className="d-flex align-items-center">
              {investor?.cmlCopyObject ? (
                <div className="position-relative d-flex gap-2 align-items-center border px-4 py-2 w-100">
                  <div className="position-absolute top-0 end-0 p-1 pointer" onClick={() => deleteDocument('cmlCopyObject')}>
                    <Close />
                  </div>

                  <AttachFile />
                  <span className="fs-normal-med text-break">{investor?.cmlCopyObject?.actualFileName}</span>
                </div>
              ) : (
                <IconButton disabled={isFileLoading || isLoading} onClick={() => document.getElementById(`input-file`).click()}>
                  <Add />
                </IconButton>
              )}
            </div>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className='d-flex'>
            <button disabled={isLoading} className="btn btn-primary" onClick={() => saveDematDetails()}>Save</button>
          </div>
        </Grid>
      </Grid>
      <CustomSnackbar
        open={openSnackbar}
        severity={toastStatus}
        duration={4000}
        text={errorData}
        handleClose={handleSnackbarClose}
      />
    </LocalizationProvider>
  );
};
export default InvestorDetails;
