import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { apiGetPartnerId, getEsignURL, getUserDetails } from "../apis/apis";
import CardLayout from "../components/layout/CardLayout";
import NumericStepper from "../components/NumericStepper";
import { getUserAuth, setPartner_Id } from "../../utils/LocalStorageUtils";
import AuthLayout from "../components/layout/AuthLayout";
import { CircularProgress } from "@mui/material";
import { useSnackbar } from "../../context/SnackbarContext";
import { fetchPartnerIdFromApi } from "../../utils/FetchLogo";

function AgreementPage(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [url, setURL] = useState("");
  const [errorData, setErrorData] = useState("");
  let history = useHistory();
  const [partnerId, setPartnerId] = useState(0);
  const { showSnackbar } = useSnackbar()
  const [mcaStatus, setMcaStatus] = useState(false);

  const handleProceed = () => {

    // in case of admin completes the mca for investor it will redirect to nomination page

    if (mcaStatus) {
      history.replace("/user/nomination")
    }
    if (url !== '') {
      setIsLoading(true);
      localStorage.setItem("MCA", true);
      window.open(url, "_self");
    }
  }

  const fetchUserData = async () => {
    const userDetails = await getUserDetails();
    if (userDetails.status) {
      if (!userDetails.data.hasMcaSet) {
        history.replace("/user/investmet-method");
      }
      if (userDetails.data.mcaStatus != 0) {
        setMcaStatus(true);
      }
    }
  }

  useEffect(() => {
    fetchUserData();
    fetchPartnerIdFromApi(
      setPartnerId,
      setPartner_Id,
      showSnackbar
    );

  }, []);

  useEffect(() => {
    setIsLoading(true);
    localStorage.setItem("MCA", false);
    getEsignURL({
      "redirectUrl": window.location.origin + "/user/mca-status"
    })
      .then(({ data, status, description }) => {
        if (status) {
          setTimeout(() => {
            setIsLoading(false);
            setURL(data.url);
          }, 2000);
        } else {
          setIsLoading(false);
          setErrorData(description);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
        setErrorData(err);
      });
  }, []);

  return (
    <AuthLayout title="Master Contribution Agreement" partner_Id={partnerId}>
      <p className='fs-normal-medium text-primary mb-2'>
        We're excited to offer you the convenience of signing documents electronically .</p>
      <p className='fs-normal-medium text-primary mb-4'>To get started , we need a little bit of information from you .</p>

      <p className='fs-normal-medium text-danger mb-4'>{errorData}
      </p>

      <div className='d-flex mt-3'>
        {isLoading ? <CircularProgress /> :
          <button type="submit" disabled={isLoading} className="btn btn-primary px-3 py-2" onClick={handleProceed}>Continue</button>}
      </div>
    </AuthLayout>
    // <CardLayout>
    //   <div className="container mt-5">
    //     {parseInt(getUserAuth()) === 1 ? <NumericStepper /> : ''}

    //     <div className="mb-5">
    //       <p className="card-text text-center">We're excited to offer you the convenience of signing documents electronically .</p>
    //       <p className="card-text text-center">To get started , we need a little bit of information from you .</p>
    //     </div>
    //     <h3 className="title-md text-center mb-4">Master Contribution Agreement</h3>

    //     {/* <p className="card-text text-center mb-5">ultricies sanctus quam ceteros malorum curae mucius odio et ea nobis<br />autem ligula interpretaris eripuit at quisque causae ea inceptos</p> */}

    //     <p className="text-center mb-4" style={{ color: "red" }}>
    //       {errorData}
    //     </p>
    //   </div>

    //   <div className="d-flex align-items-center flex-column mb-5">
    //     <button className="color-btn mx-auto" onClick={handleProceed} disabled={isLoading}>
    //     {isLoading ? "Loading..." : "CONTINUE"}
    //     </button>
    //     {parseInt(getUserAuth()) === 1 ? 
    //     <div
    //       className="text-color fs-4 skip-text mt-3"
    //       onClick={() => {
    //         history.replace("/user/home");
    //       }}
    //     >
    //       SKIP FOR NOW
    //     </div> : ''}
    //   </div>
    // </CardLayout>
  );
}

export default AgreementPage;
