import { Checkbox, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from "react";
import { Close, Search } from '@mui/icons-material';
import Pagination from "@mui/material/Pagination";
import { RECORD_DISPLAY_LIMIT } from '../../utils/constants/constants';
import { apiCompanyUpdates, getDataDictionaryValues } from '../apis/apis';
import CompanyUpdateModal from '../components/CompanyUpdateModal';

const AllActivity = () => {
    const [open, setOpen] = useState(false);
    const [tagsList, setTagsList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [companyUpdateList, setCompanyUpdateList] = useState({});
    const [cuRid, setCuRid] = useState(null);
    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");

    const handleOpen = (id) => {
        setOpen(true);
        setCuRid(id);
    }
    const handleClose = () => {
        setOpen(false);
        setCuRid(null);
    }

    const formatDate = (inputDate) => {
        const dateParts = inputDate.split('-');
        const year = dateParts[0];
        const month = parseInt(dateParts[1], 10);
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        const monthName = monthNames[month - 1];
        return `${monthName} ${year}`;
    }

    const handleTagCheckbox = (index) => {
        const updatedList = tagsList.map(item =>
            item.ddIndex === index ? { ...item, isChecked: !item.isChecked } : item
        );
        setTagsList(updatedList);
    }

    const handleCompanyUpdateList = (data) => {
        const groupedData = {};

        data.forEach(item => {
            const date = new Date(item.publishDate);
            const monthYear = `${date.getFullYear()}-${(date.getMonth() + 1)
                .toString()
                .padStart(2, "0")}`;

            if (!groupedData[monthYear]) {
                groupedData[monthYear] = [];
            }
            groupedData[monthYear].push(item);
        });

        setCompanyUpdateList(groupedData);
    }

    const getDataDictionary = () => {
        getDataDictionaryValues()
            .then((data) => {
                const tags = data.filter((item) =>
                    item.ddType === 11
                )
                setTagsList(
                    tags.map((item) => ({ ...item, isChecked: false }))
                );
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const getCompanyUpdateList = (params = {}) => {
        if (typeof params !== "object" || params === null) {
            params = {};
        }

        const selTags = tagsList
            .filter(item => item.isChecked)
            .map(item => item.ddIndex)
            .join(',');

        params.pageNumber = params.pageNumber || page;
        params.name = searchTerm.trim();
        params.tags = selTags;
        params.recLimit = RECORD_DISPLAY_LIMIT;

        setIsLoading(true);
        apiCompanyUpdates(params)
            .then((data) => {
                setIsLoading(false);
                setTotalRecords(data.data.records);
                handleCompanyUpdateList(data.data.companyUpdatesList);
            })
            .catch((e) => {
                setIsLoading(false);
                // enqueueSnackbar(e,SNACKBAR_ERROR)
            });
    };


    useEffect(() => {
        getCompanyUpdateList({ pageNumber: page });
    }, [page]);

    useEffect(() => {
        setPage(1);
        getCompanyUpdateList({ pageNumber: 1 });
    }, [searchTerm, tagsList]);

    const handlePageChange = (_event, value) => {
        setPage(value);
        getCompanyUpdateList({ pageNumber: value });
    };

    useEffect(() => {
        getDataDictionary();
    }, []);

    return (
        <div className="d-flex gap-5 my-4">
            <CompanyUpdateModal
                open={open}
                closeAction={handleClose}
                companyUpdateRid={cuRid}
            />
            <div className="d-flex flex-column mt-20">
                <p className='align-items-center justify-content-center fs-med-regular ' style={{ padding: "1.2rem", marginBlockEnd: "0.2em", paddingBottom: "1.5rem" }}>Tags</p>
                {tagsList.map((data, key) => {
                    return (
                        <div className="d-flex align-items-center" key={key} style={{ paddingLeft: "1.2rem", paddingTop: "0.6rem" }}>
                            <Checkbox checked={data.isChecked} onChange={() => handleTagCheckbox(data.ddIndex)} className='m-0 p-0' />
                            <div className="d-flex flexgrow-1">
                                <p className="fs-med-regular p-0 m-0">{data.ddValue}</p>
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="d-flex gap-3 flex-column flex-grow-1" >
                <div className='d-flex justify-content-between align-items-center'>
                    <div className="d-flex align-items-center p-3 bg-white form-control w-auto rounded-pill" >
                        <Search fontSize="small" color="secondary" />
                        <input
                            type="text"
                            placeholder="Companies"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="fs-med-regular w-100 px-2"
                            style={{ border: 'none', outline: 'none' }}
                        />
                        <Close fontSize="small" color="secondary" className="custom-link" onClick={() => setSearchTerm('')} />
                    </div>
                    <div className="justify-content-evenly d-flex align-items-right" >
                        <Pagination color="primary" size="small" siblingCount={0} onChange={handlePageChange} count={totalRecords < RECORD_DISPLAY_LIMIT ? 1 : Math.ceil(totalRecords / RECORD_DISPLAY_LIMIT)} page={page} />
                    </div>
                </div>
                {Object.keys(companyUpdateList).map((yearMonth) => {
                    return (
                        <div className='mb-3'>
                            <p className="fs-med-regular p-0 m-0 text-muted mb-2">{formatDate(yearMonth).toUpperCase()}</p>
                            {companyUpdateList[yearMonth]?.map((data, key) => {
                                return (
                                    <div key={key} className="card pointer p-3 mb-2" onClick={() => handleOpen(data.cuRid)}>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex flex-column flex-grow-1">
                                                <h5 className="fs-6 mb-1">{data.title}</h5>
                                                <p className="fs-normal-medium text-muted p-0 m-0">{data.authorName}</p>
                                            </div>
                                            <div >
                                                <img
                                                    src={
                                                        data.logoUrl ?
                                                            process.env.REACT_APP_BASE_URL + "/" + data.logoUrl :
                                                            process.env.PUBLIC_URL + "/img/placeholder.png"
                                                    }
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null;
                                                        currentTarget.src = process.env.PUBLIC_URL + "/img/placeholder.png";
                                                    }}
                                                    alt="company-banner"
                                                    className="thumbnail-img"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
                {isLoading && <CircularProgress className='mx-auto mt-3' />}
            </div>
        </div>
    );
}

export default AllActivity;