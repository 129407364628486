
import React, { useState, useEffect } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { useHistory } from 'react-router-dom';
import CustomSnackbar from '../../components/Toast/CustomSnackbar';
import AuthLayout from '../../components/layout/AuthLayout';
import { apiSaveAccreditation, getUserDetails } from '../../apis/apis';

const AccreditationDisclosure = () => {
    const [confirmed, setConfirmed] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [toastStatus, setToastStatus] = useState('');
    const [initialCheckComplete, setInitialCheckComplete] = useState(false);

    const history = useHistory();

    useEffect(() => {
        checkInitialAccreditationStatus();
    }, []);

    useEffect(() => {
        window.history.pushState(null, "", window.location.pathname);
        const preventNavigation = (e) => {
            window.history.pushState(null, "", window.location.pathname);
        };
        window.addEventListener('popstate', preventNavigation);
        return () => {
            window.removeEventListener('popstate', preventNavigation);
        };
    }, []);

    const checkInitialAccreditationStatus = async () => {
        try {
            const userDetails = await getUserDetails();
            if (userDetails?.data?.isAccredited === 0 || userDetails?.data?.isAccredited === null) {
                history.push("/user/accreditation-disclosure");
            } else if (userDetails?.data?.mcaStatus === null || userDetails?.data?.mcaStatus === 0) { // SPNVC-530 - remove pending mca page
                console.log("mca")
                history.push("/user/mca-status")
            } else if (userDetails.data.mcaAmount === null || userDetails.data.mcaAmount === 0 && userDetails?.data?.isAccredited === 1) {
                history.replace("/user/investmet-method");
            } else {
                console.log("nomination")
                history.push("/user/nomination");
            }
        } catch (error) {
            showToast("Failed to fetch user details", "error");
        } finally {
            setInitialCheckComplete(true);
        }
    };

    const handleConfirmation = (event) => {
        setConfirmed(event.target.checked);
    };

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const showToast = (message, status = 'error') => {
        setToastStatus(status);
        setErrorMessage(message);
        setOpenSnackbar(true);
    };

    const handleProceed = async () => {
        if (!confirmed) {
            showToast("Please confirm the accreditation requirements", "error");
            return;
        }
        setButtonClicked(true);

        setIsLoading(true);
        try {
            await apiSaveAccreditation();
            const userDetails = await getUserDetails();
            const response = await apiSaveAccreditation();

            showToast(response.description, 
                response.status ? "success" : "error");

            if (userDetails.data.isAccredited === 1 && userDetails.data.mcaAmount === 0 || userDetails.data.mcaAmount === null) {
                setTimeout(() => {
                    history.replace("/user/investmet-method");
                }, 1000);
            } else
                if (
                        (userDetails.data.dobDoi === null || userDetails.data.dobDoi === "") ||
                    (userDetails.data.address === null || userDetails.data.address === "") ||
                    (userDetails.data.investorType === null || userDetails.data.investorType === "") ||
                    (userDetails.data.cityRid === 0) || (userDetails.data.countryRid === 0)
                ) {
                    history.push("/user/communication-details");
                } else if (
                    ((userDetails.data.bankName === '' || userDetails.data.bankName === null) && (userDetails.data.bankNameIndex === 0 || userDetails.data.bankNameIndex === null)) ||
                    (userDetails.data.bankAddress === '' || userDetails.data.bankAddress === null) ||
                    (userDetails.data.accountNo === '' || userDetails.data.accountNo === null) ||
                    (userDetails.data.ifscSwift === '' || userDetails.data.ifscSwift === null)) {
                    history.push("/user/bank-details")
                }
                else if (!userDetails.data.mcaStatus) {
                    history.push("/user/successfull");
                } else if (userDetails?.data?.mcaStatus === null || userDetails?.data?.mcaStatus === 0) { // SPNVC-530 - remove pending mca page
                    history.push("/user/mca-status");
                }
                else {
                    console.log("nomination")
                    history.push("/user/nomination");
                }
        } catch (error) {
            const errorMessage = error.response?.data?.description || error.message || "An error occurred";
            showToast(errorMessage, "error");
            setButtonClicked(false);
        } finally {
            setIsLoading(false);
        }
    };
    if (!initialCheckComplete) {
        return (
            <AuthLayout title="Accreditation Disclosure">
                <div className="d-flex justify-content-center align-items-center" style={{ height: '200px' }}>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </AuthLayout>
        );
    }

    return (
        <AuthLayout title="Accreditation Disclosure">
            <div className="disclosure-content">
                <p className="mb-4">
                    Pursuant to recent amendments to SEBI's AIF Regulations, only Accredited Investors are allowed to invest in Venture Capital funds.
                </p>
                <p className="mb-4">
                    "Accredited investor" means any person who is granted a certificate of accreditation by an accreditation agency who,
                </p>
                <div className="ps-4 mb-4">
                    <p className="mb-3">(i) in case of an individual, Hindu Undivided Family, family trust or sole proprietorship has:</p>
                    <ul className="list-unstyled ps-4">
                        <li className="mb-2">(A) annual income of at least two crore rupees; or</li>
                        <li className="mb-2">(B) net worth of at least seven crore fifty lakh rupees, out of which not less than three crores seventy-five lakh rupees is in the form of financial assets; or</li>
                        <li className="mb-2">(C) annual income of at least one crore rupees and minimum net worth of five crore rupees, out of which not less than two crore fifty lakh rupees is in the form of financial assets.</li>
                    </ul>
                    <p className="mb-2">(ii) in case of a body corporate, has net worth of at least fifty crore rupees;</p>
                    <p className="mb-2">(iii) in case of a trust other than family trust, has net worth of at least fifty crore rupees;</p>
                    <p>(iv) in case of a partnership firm set up under the Indian Partnership Act, 1932, each partner independently meets the eligibility criteria for accreditation</p>
                </div>

                <div className="d-flex mt-5 justify-content-between align-items-center">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={confirmed}
                                onChange={handleConfirmation}
                                color="primary"
                                disabled={isLoading}
                            />
                        }
                        label="I hereby confirm the same"
                    />
                    <button
                        className="btn btn-primary px-4"
                        disabled={!confirmed || isLoading || buttonClicked}
                        onClick={handleProceed}
                    >
                        {isLoading ? (
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        ) : (
                            'Proceed'
                        )}
                    </button>
                </div>
            </div>

            <CustomSnackbar
                open={openSnackbar}
                severity={toastStatus.toLowerCase()}
                duration={4000}
                text={errorMessage}
                handleClose={handleSnackbarClose}
            />
        </AuthLayout>
    );
};

export default AccreditationDisclosure;