import React, { useEffect, useState } from 'react';
import AuthLayout from '../components/layout/AuthLayout';
import { getUserDetails, getEsignURL } from '../apis/apis';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Tooltip, CircularProgress } from '@mui/material';

function SuccessfullPage() {

    const history = useHistory();
    const [url, setURL] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [ckycStatus, setCkycStatus] = useState(0);
    const [mcaStatus, setMcaStatus] = useState(0);
    const [errorData, setErrorData] = useState("");
    const [hasMcaSet, setHasMcaSet] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const submit = async () => {
        setIsLoading(true);
        setIsButtonDisabled(true);

        try {
            const userDetails = await getUserDetails();
            if (userDetails.status) {
                const { mcaStatus, ckycStatus, hasMcaSet, mcaAmount } = userDetails.data;
                if (mcaStatus) {
                    setIsLoading(false);
                    history.replace("/user/nomination");
                    return;
                } else if (mcaStatus === 0|| mcaStatus === null) {
                    history.replace("/user/agreement");
                } else
                    if (ckycStatus && mcaStatus !== 0) {
                        history.replace("/user/home");
                    } else if (ckycStatus && mcaAmount === 0 || mcaAmount === null) {
                        history.replace("/user/investmet-method");
                    } else if (ckycStatus === 0) {
                        history.replace("/user/kyc");
                    }
            }
        } catch (error) {
            setErrorData("An error occurred. Please try again.");
            setIsButtonDisabled(false);
        } finally {
            setIsLoading(false);
        }
    };




    useEffect(() => {
        const fetchData = async () => {
            try {
                const userDetails = await getUserDetails();

                if (userDetails.status) {
                    const { mcaStatus, ckycStatus, countryCode, hasMcaSet } = userDetails.data;
                    localStorage.setItem("CountryCode", countryCode);
                    setCkycStatus(ckycStatus);
                    setMcaStatus(mcaStatus);
                    setHasMcaSet(hasMcaSet);
                    if (ckycStatus && mcaStatus !== 0) {
                        history.replace("/user/home");
                    }

                }
            } catch (error) {
                console.error("Error fetching user details:", error);
                setErrorData("Failed to load user data. Please try again.");
            }
        };

        fetchData();
    }, [history]);

    const skipForNow = () => {
        history.replace("/user/home");
    };

    return (
        <AuthLayout title="Successfully Registered">
            <p className='fs-5'>Continue with {ckycStatus ? 'MCA E-Sign' : 'CKYC verification'}</p>
            <div className='d-flex mt-3'>
                {isLoading ? (
                    <div className="d-flex align-items-center justify-content-center" style={{ width: 100, height: 40 }}>
                        <CircularProgress size={30} thickness={5} style={{ color: '#5F58FF' }} />
                    </div>
                ) : (
                    <button
                        type="submit"
                        onClick={submit}
                        className="btn btn-primary px-3 py-2"
                        disabled={isButtonDisabled}
                    >
                        Continue
                    </button>
                )}

                {ckycStatus && !localStorage.getItem("SIGN_UP") ?
                    <button
                        type="button"
                        className="btn btn-light px-3 ms-3 py-2"
                        onClick={() => {
                            history.push("/user/home")
                        }}
                    >
                        Skip For Now
                    </button> : null
                }
            </div>

            <div className="mt-3">
                <Tooltip
                    placement="bottom"
                    title={
                        <p> SCIF, or Salil Chakrabarty Innovation Fund, is a SEBI Registered Category
                            1 Venture Capital Fund - Angel Fund bearing License No IN/AIF1/22-23/1102.
                            KYC Verification is mandatory for investing in Regulated Financial Products in India
                            under regulation of SEBI (Securities and Exchange Board of India).</p>
                    }
                    enterTouchDelay={0}
                >
                    <div>
                        <a className='text-decoration-none text-primary'>Why is this needed?</a>
                    </div>
                </Tooltip>
            </div>

        </AuthLayout>
    );
}

export default SuccessfullPage;